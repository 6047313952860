.header {
  background-color: rgb(255, 255, 255);
  height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.header-scroll {
  background-color: rgba(255, 255, 255);
  height: 12vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1.6rem;
}

.header-scroll .main-menu a {
  padding: 0.5rem;
  color: rgb(0, 0, 0);
}

header a {
  transition: 0.5s;
}

.header__middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1rem;
}

.header__middle__logo img {
  width: 9rem;
  padding: 0.3rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

nav .menu {
  display: none;
  position: absolute;
  top: 1.75rem;
  right: 2.75rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
  cursor: pointer;
}

nav .menu span {
  height: 0.3rem;
  width: 100%;
  background-color: #1c1c1c;
  border-radius: 0.2rem;
  
}

.main-nav {
  display: flex;
  align-items: center;
}

.main-menu {
  
  display: flex;
  column-gap: 1.5rem;
  padding-right: 5em;
  align-items: center;
  width: max-content;
}

.main-menu a {
  
  padding: 0.5rem;
  color: #000;
}
.main-menu a:hover {
  
 /* text-decoration-line:underline ; */
}

.sub__menus__arrows {
  position: relative;
}

.sub__menus__arrows a {
  color: #000;
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
}

.sub__menus {
  
  text-align: left;
  position: absolute;
  font-size: 15px;
  top: 100%; /* Adjust position relative to the parent menu item */
  left: 0; /* Ensure the dropdown aligns with the parent */
  width: 12rem;
  z-index: 1000; /* Increase z-index to ensure it's displayed on top */
  display: none; /* Hide the dropdown by default */
}

.sub__menus a {
  background: white;
  color: black;
}
.sub__menus a:hover {
  background: rgb(255, 255, 255);
  transition: none;
  color: #224acd;
  
}

.menu-item {
  position: relative; /* Ensure menu items are positioned relatively */
}
.sub__menus a:hover {
  /* color: rgb(0, 88, 146); */
}

.sub__menus__arrows:hover .sub__menus {
  display: block;
  visibility: visible;
  opacity: 1;
}

.main-menu li:hover .sub__menus {
  display: block;
  visibility: visible;
  opacity: 1;
}

.apply-btn {
  background: rgb(0, 88, 146);
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
}
.main-menu .apply-btn {
  color: #fff;
}


.main-menu .apply-btn:hover {
  background-color: #ff0000;
  color: #fff;
}

.phone-icon {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.phone-icon:hover {
  color: #224acd;
}

@media (max-width: 1180px) {
  nav .menu {
    display: flex;
  }
  nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background: #1d64b1;
  }
  nav ul {
    display: none;
    
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }
  nav ul .open {
    display: flex;
  }
  nav .main-menu ul li {
    
    min-width: 100%;
    text-align: center;
  }
  nav ul li {
    margin: 0.2rem 0.5rem;
  }

  .main-menu {
    display: none;
    flex-direction: column;
    align-items: center; /* Align items to the right */
    margin-right: 0;
    margin-left: 0;
    margin-top: 100vh; /* Adjust as needed */
    height: 100vh;
    width: 100%; /* Adjusted to take full width */
    padding-top: 5rem; /* Adjusted padding as needed */
    z-index: 99;
  }

  .main-menu.open {
    display: flex;
  }

  .main-menu a {
    padding: 1rem;
    margin-top: 1rem;
    color: #fff; /* Set text color to black */
  }

  .menu-item:hover .sub__menus,
  .sub__menus:hover {
    display: block; /* Ensure the dropdown stays open when hovered */

    visibility: visible;
    opacity: 1;
  }
  .main-menu .apply-btn {
    display: block; /*"Apply Now" button appears as a block */
    margin-top: 1rem; /* top margin to separation  */
  }

  .sub__menus a {
    min-width: min-content;
    background: white;
    color: #000; /* Set text color to black */
  }

  .sub__menus a:hover {
    color: #000;
    background-color: #ffffff; /* Hover color for dropdown menu items */
  }

  .main-menu .apply-btn {
    background: rgb(35, 156, 237);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .main-menu .apply-btn {
    color: #fff;
  }
}
