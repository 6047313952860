/* Set margin and padding to 0 for the whole page */
html, body {
  background-color: white; /* Set background color of the body to white */
  margin: 0;
  font-family: 'Poppins', sans-serif; /* Set 'Poppins' font family for the body */
}

.va-container, .fha-container {
  text-align: center; /* Align text center */
}

.va-image-container, .fha-image-container {
  width: 100%;
  height: 65vh; /* Set height of the image container */
  overflow: hidden; /* Ensure the image doesn't overflow its container */
  margin-bottom: 20px; /* Add some space below the image */
}

.va-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center; /* Center the image within its container */
}

h1, h2 {
  font-family: 'Poppins', sans-serif; /* Apply the 'Poppins' font family */
  font-size: 28px; /* Set the font size to 28 pixels */
  font-weight: normal; /* Set the font weight to normal */
  color: black; /* Set the text color to black */
  text-align: center; /* Align the text center */
  margin: 20px auto; /* Center headings and add some margin below */
}

p {
  width: 80%; /* Set width of paragraphs */
  margin: 0 auto 20px; /* Center paragraphs horizontally and add margin below */
  font-size: 18px; /* Set font size for paragraphs */
  line-height: 1.6; /* Improve readability with line-height */
  text-align: justify; /* Justify paragraph text */
}

ol {
  width: 80%; /* Set width of the ordered list */
  margin: 0 auto 20px; /* Center the list horizontally and add margin below */
  text-align: left; /* Align text to the left */
  padding-left: 20px; /* Add padding to the left for proper indentation */
  list-style-position: inside; /* Align list items with the text */
}

ol li {
  font-size: 18px; /* Set font size for list items */
  line-height: 1.6; /* Improve readability with line-height */
  margin-bottom: 10px; /* Add margin below each list item */
  padding-left: auto;
}

button.apply-button {
  display: block; /* Ensure button is block-level for centering */
  margin: 20px auto; /* Center the button horizontally and add margin to top */
  padding: 15px 30px; /* Add padding to button */
  font-size: 18px; /* Set font size */
  background-color: #007bff; /* Blue background color */
  color: white; /* White text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius */
  cursor: pointer; /* Set cursor to pointer */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

button.apply-button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}
