
.quick-quote-form {
  width: 100%;
  max-width: 600px;
  margin: 90px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.quick-quote-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: calc(100% - 10px);
  padding: 8px 5px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group div {
  display: flex;
  gap: 10px;
}

.form-group input[type="radio"] {
  width: auto;
  margin-right: 5px;
}

.qqButton {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.qqButton:hover {
  background-color: #0056b3;
}

.quick-quote-image{
  width: 100%;
  height: 475px;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center top; /* Center the image within its container */

}
.overlayquick-quote{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:475px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
  .overlay-text-quick-quote{
    position: absolute; /* Position the text absolutely */
    top: 35%; /* Center vertically */
    left: 35%; /* Center horizontally */
    color: white; /* Text color */
    font-size: 36px; /* Font size */
    font-weight: bold; /* Font weight */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for better visibility */
  }
/* For smaller screens, adjust form width and margin */
@media only screen and (max-width: 650px) {
  .quick-quote-form {
    width: 80%; /* Adjust width to fit smaller screens */
    margin: 30px auto; /* Adjust margin for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .overlay-text-quick-quote {
    top:30%;
    left:30%;
    font-size: 24px; /* Adjust font size for smaller screens */
  }
  
  .quick-quote-image,
  .overlayquick-quote {
    height: 350px; /* Adjust height for smaller screens */
  }
}

/* Styles for larger screens (1920px or wider) */
@media (min-width: 1920px) {
  .quick-quote-form {
    max-width: 800px; /* Increase max width */
    margin: 120px auto; /* Add more margin for spacing */
    padding: 40px; /* Increase padding for a spacious layout */
    font-size: 1.2em; /* Scale up font size for better readability */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Enhance shadow for better definition */
  }

  .form-group label {
    font-size: 1.1em; /* Slightly larger label font size */
  }

  .form-group input,
  .form-group select {
    padding: 12px; /* Increase input padding for larger screens */
    font-size: 1.1em; /* Larger font size for inputs */
  }

  .qqButton {
    padding: 15px; /* Add padding for a bigger button */
    font-size: 1.3em; /* Increase font size for the button */
  }

  .quick-quote-image {
    height: 600px; /* Adjust image height for larger screens */
  }

  .overlay-text-quick-quote {
    top: 30%; /* Center text vertically */
    left: 40%; /* Center text horizontally */
    font-size: 48px; /* Larger font size for overlay text */
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6); /* Enhance text shadow for visibility */
  }

  .overlayquick-quote {
    height: 600px; /* Match image height */
  }
}
