/* Existing styles */
.awards-section {
  padding: 20px 0;
  background-color: #f9f9f9;
}

.awards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.awards-image {
  position: relative;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  height: 100%;
  z-index: 1;
}

.awards-content {
  flex: 1;
  padding: 20px;
}

.awards-content h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.awards-content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.awards-content ul li {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  position: relative;
  padding-left: 100px;
}

.awards-content ul li::before {
  content: '✔';
  color: red;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  padding-left: 75px;
}

.awards-apply-now {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: red;
  border-radius: 5px;
  text-decoration: none;
  margin-left: 200px;
}

.awards-apply-now:hover {
  background-color: darkred;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .awards-container {
    flex-direction: column;
    align-items: center;
  }

  .awards-content {
    padding: 10px;
    text-align: center; /* Center align the text in awards content */
  }

  .awards-content h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .awards-content ul li {
    font-size: 14px;
    padding-left: 25px;
    text-align: left; /* Ensure list items are left-aligned */
  }

  .awards-content ul li::before {
    padding-left: 5px;
  }

  .awards-apply-now {
    margin-left: auto; /* Align the button to the right */
    margin-right: auto; /* Align the button to the left */
    margin-top: 20px;
    text-align: center;
    align-self: center; /* Center the button in its container */
  }
}

@media (max-width: 480px) {
  .awards-content h1 {
    font-size: 18px;
  }

  .awards-content ul li {
    font-size: 12px;
    padding-left: 30px; /* Further adjust padding for small screens */
    text-align: left; /* Ensure list items are left-aligned */
  }

  .awards-content ul li::before {
    padding-left: 10px; /* Further adjust checkmark position */
  }

  .awards-apply-now {
    font-size: 14px;
    margin: 20px auto 0 auto; /* Center the button */
    display: block; /* Ensure it's treated as a block-level element */
    text-align: center;
    align-self: center; /* Center the button in its container */
  }
}
