.calculator-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 4rem 2rem 2rem; /* Added top padding for gap */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}


.calculator-title {
  padding-top:100px;
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 20px;
  color: #007bff;
  animation: slideInDown 1s ease-in-out;
}

.calculator-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: slideInUp 0.5s ease-in-out;
}

.input-group label {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.calculate-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  animation: slideInUp 0.5s ease-in-out;
}

.calculate-button:hover {
  background-color: #0056b3;
}

.savings-result {
  margin-top: 20px;
  font-size: 1.5em;
  color: #28a745;
  animation: fadeIn 1s ease-in-out;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
