.privacy-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-title {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-content {
    line-height: 1.6;
  }
  
  .privacy-content h2 {
    margin-top: 20px;
  }
  
  .privacy-content p {
    margin-bottom: 20px;
  }
  