/* Set margin and padding to 0 for the whole page */
html, body {
  margin: 0;
  padding: 0;
}

/* Base styles for headings and text */
h1, h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: normal;
  color: black;
  text-align: center;
  margin: 20px auto;
}

p {
  width: 80%;
  margin: 0 auto 20px;
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
}

ol {
  width: 80%;
  margin: 0 auto 20px;
  text-align: left;
  padding-left: 20px;
  list-style-position: inside;
}

ol li {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

button.apply-button {
  display: block;
  margin: 20px auto;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button.apply-button:hover {
  background-color: #0056b3;
}

/* Base styles for the Jumbo component */
.jumbo-container {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}




/* Mobile styles */
@media screen and (max-width: 768px) {
  /* Adjust the overlay text for mobile */
  .overlay-text-jumbo {
    font-size: 23px; /* Smaller font size for mobile */
    top: 60%; /* Adjust top position for better visibility */
  }

  /* Adjust heading and paragraph text for mobile */
  h1, h2 {
    font-size: 24px; /* Smaller font size for mobile */
    text-align: center;
    margin: 10px auto; /* Adjust margin for mobile */
  }

  p, ol {
    width: 90%; /* Increase width for mobile */
    font-size: 16px; /* Smaller font size for mobile */
    margin: 10px auto; /* Adjust margin for mobile */
  }

  ol {
    padding-left: 15px; /* Decrease padding for mobile */
  }

  ol li {
    font-size: 16px; /* Smaller font size for mobile */
    margin-bottom: 5px; /* Adjust margin for mobile */
  }

  button.apply-button {
    padding: 10px 20px; /* Adjust padding for mobile */
    font-size: 16px; /* Smaller font size for mobile */
    margin: 10px auto; /* Adjust margin for mobile */
  }

  .jumbo-container {
    padding: 10px; /* Adjust padding for mobile */
  }
}
