.teams {
    text-align: center;
    padding: 20px;
  }
  
  .teams h1 {
    margin-bottom: 20px;
  }
  
  .team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card {
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .card h2 {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #333;
    font-size: 1.5em;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #0073b1; /* LinkedIn color */
  }
  