/* Set margin and padding to 0 for the whole page */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

/* Style the container for the ARM component */
.fha-container {
  text-align: center;
  position: relative; /* Make the container relative for absolute positioning */
}

.fha-image {
  position: relative; /* Ensure relative positioning for z-index */
  width: 100%;
  height: 80vh; /* Set the height to half of the viewport height */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center bottom; /* Position the image to show the top half */
  margin-bottom: 20px; /* Add margin below the image */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

/* Style the container for the FHA component */
.fha-container {
  text-align: center;
  position: relative; /* Make the container relative for absolute positioning */
}

/* Style the container for the image */
.fha-image-container {
  position: relative;
}

/* Style the image */
.fha-image {
  width: 100%;
  height: 100vh; /* Set the height to half of the viewport height */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center bottom; /* Position the image to show the top half */
  margin-bottom: 20px;
}

/* Style the black gradient overlay */
.overlayh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

/* Style the text overlay */
.overlay-text-fha {
  position: absolute;
  top: 50%; /* Adjust top position */
  left: 50%; /* Adjust left position */
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Style paragraphs to be justified */
p {
  text-align: justify;
}

/* Adjust margin for paragraph based on screen size */
@media (max-width: 768px) {
  .fha-heading ,.fha-benefit{
    margin-left: 19px; /* Adjust margin for smaller screens */
  }
  .overlay-text-fha {
    font-size: 26px;

  }
}
