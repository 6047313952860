.featured {
  position: relative;
}


.featured .heading {
  text-align: center;
  margin-bottom: 20px;
}

.featured .heading .welcome {
  font-size: 24px;
  color: #333;
}

.featured .heading .subtitle {
  font-size: 18px;
  color: #666;
}

.featured-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

.featured-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 45%;
  text-align: left;
  margin-bottom: 20px; /* Add margin to handle space between cards when stacked */
}

.featured-card h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.featured-card p {
  font-size: 16px;
  color: #666;
}

.featured-apply-now {
  display: inline-block;
  margin-top: 20px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  float: right; /* This aligns the button to the right */
}

.featured-apply-now:hover {
  text-decoration: underline;
}

.featured-icon {
  width: 50px;  /* Adjust the size as needed */
  height: 50px;  /* Adjust the size as needed */
  display: block;
  margin-bottom: 15px;  /* Space between icon and heading */
  margin-left: auto;
  margin-right: auto;
}

/* Updated class for image size */
.featured .feature-image {
  width: 50px;  /* Adjust the size as needed */
  height: 50px;  /* Adjust the size as needed */
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .featured-container {
    flex-direction: column;
    align-items: center;
  }

  .featured-card {
    width: 90%; /* Full width on mobile with some padding */
  }
}
