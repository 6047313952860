.back {
  background-size: cover;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  text-align: center;
  color: white;
  
}
.back-content {
  
  padding: 20px;
  display: inline-block;
}
.back-content h1{
  color: white;
  padding: 50px;
  display: inline-block;
}
.back-content h2{

transform: translate(-50%, -50%);
color: white;
font-size: 36px;
font-weight: bold;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
z-index: 2; /* Ensure text is above the overlay */

}
