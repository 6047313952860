/* Existing styles ... */

.refinance-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  color: #333333;
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

.refinance-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: justify;
}

.refinance-container p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  margin-left: 20px;
  text-align: justify;
}

.refinance-container h2 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-left: 20px;
  text-align: justify;
}

.refinance-container ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 20px;
  text-align: justify;
}

.refinance-container ul li {
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: justify;
}

.refinance-buttons {
  display: flex; /* Align items in a row */
  justify-content: center;
  align-items: center; /* Center the buttons horizontally */
  gap: 40px; /* Add space between buttons */
  margin-top: 20px; /* Maintain the top margin */
  
}

.apply-button,
.refinance-button {
  background-color: #007bff; /* Same background color for both */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px; /* Uniform font size */
  width: 200px; /* Fixed width for consistency */
  text-align: center;
  border: none; /* Remove any border */
  cursor: pointer;
}

.apply-button:hover,
.refinance-button:hover {
  background-color: #0056b3; /* Same hover effect */
}


.refinance-image {
  width: 100%;
  height: 475px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
  position: relative;
}

.overlay-text-refinance {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 2;
}

.overlayrefinance {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 475px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1;
}

/* Media Queries for Mobile View */
@media (max-width: 400px) {
  .refinance-container {
    padding: 10px;
    text-align: center;
  }

  .refinance-container h1 {
    font-size: 1.5em;
  }

  .refinance-container p,
  .refinance-container h2,
  .refinance-container ul {
    margin-left: auto;
    margin-right: auto;
    font-size: 1em;
  }

  .refinance-container ul {
    padding-left: 0;
    list-style-position: inside;
  }

  .refinance-container ul li {
    font-size: 1em;
  }

  .refinance-buttons {
    flex-direction: column;
    align-items: center;
  }

  .apply-button,.refinance-button {
    font-size: 16px;
    padding: 16px 16px;
  }

  .content {
    flex-direction: column;
    align-items: center;
  }

  .content img {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .text {
    margin-left: auto;
    margin-right: auto;
  }

  .refinance-image {
    height: 40%;
  }

  .overlayrefinance {
    height: 45%;
  }

  .overlay-text-refinance {
    font-size: 24px;
    position: absolute;
      top: 30%; /* Center vertically */
      left: 50%; /* Center horizontally */
      transform: translate(-50%, -50%); /* Center the text */
      color: white;
      font-size: 36px;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
      z-index: 2;
    
  }
}



.refinance-container h2 {
  margin-left: 100px;
}

/* Responsive styles for large screens (1920px or wider) */
@media (min-width: 1920px) {
  .refinance-container {
    max-width: 1200px; /* Increase max-width to utilize more space */
    margin: 80px auto; /* Add more margin for better positioning */
    padding: 40px; /* Increase padding */
    font-size: 1.2em; /* Scale up font size slightly */
  }

  .refinance-container h1 {
    font-size: 2.5em; /* Larger heading */
    margin-bottom: 30px;
  }

  .refinance-container p {
    font-size: 1.4em; /* Larger text for readability */
    line-height: 1.8;
  }

  .refinance-container h2 {
    font-size: 2em; /* Larger subheading */
    margin-top: 30px;
  }

  .refinance-container ul {
    margin-left: 50px;
  }

  .refinance-container ul li {
    font-size: 1.3em;
  }

  .refinance-buttons {
    gap: 60px; /* Increase spacing between buttons */
    margin-top: 30px;
  }

  .apply-button,
  .refinance-button {
    font-size: 18px; /* Slightly larger buttons */
    padding: 16px 32px; /* Increase padding for better proportion */
    width: 240px; /* Wider buttons for better fit */
  }

  .refinance-image {
    height: 600px; /* Taller image for larger screens */
  }

  .overlay-text-refinance {
    font-size: 42px; /* Larger overlay text */
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
  }

  .overlayrefinance {
    height: 600px; /* Match image height */
  }

  .textb {
    margin-left: 120px; /* Adjust margins for better spacing */
  }
}

