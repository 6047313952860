
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    text-align: left;
    position: relative;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .modal-content ul li {
    margin-bottom: 10px;
  }
  
  .close-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #0056b3;
  }
  