.footer {
  background-color: #2d3748;
  color: white;
  padding: 32px 0;
  font-size: 14px; /* Set the base font size */
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.footer-left,
.footer-center,
.footer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}

.footer-left {
  flex: 1;
}

.footer-center {
  flex: 1;
}

.footer-right {
  flex: 1;
}

.footer-logo {
  height: 80px; /* Resized the logo */
  width: 280px;
  margin-bottom: 16px;
  animation: fadeIn 1s ease-in-out;
}

.footer-email {
  text-transform: lowercase;
  color: #fff;
  text-decoration: underline;
}

.footer-icons {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.footer-icon {
  color: #1e90ff;
  font-size: 24px;
  text-decoration: none;
}

.footer-icon:hover {
  color: #3748ff;
}

.footer-links {
  display: flex;
  gap: 16px;
}

.footer-link {
  text-decoration: underline;
  color: white;
}

.footer-link:hover {
  color: #1e90ff;
}

.footer-bottom {
  border-top: 1px solid #4a5568;
  margin-top: 24px;
  padding-top: 16px;
  text-align: center;
  font-size: 14px;
}

.footer-bottom p {
  text-align: center;
  font-size: 14px;
}

/* Centered Images Section */
.footer-images {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.footer-image-container {
  display: flex;
  gap: 40px;
}

.footer-image {
  width: 60px;
  height: auto; /* Maintain aspect ratio */
}

/* Responsive Design */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    text-align: left;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    align-items: flex-start;
  }

  .footer-left {
    text-align: left;
  }

  .footer-center {
    text-align: left;
  }

  .footer-right {
    text-align: left;
    align-items: flex-start;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
