.hero {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../../images/landings.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 138vh; /* Adjusted height to cover full viewport height */
  width: 100%;
  position: relative;

  
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttons button {
  background: rgb(0, 88, 146);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  transition: 0.2s ease-in;
  font-size: 20px;
}

.buttons button:hover {
  background-color: #ffffff;
  color: rgb(0, 88, 146);
}

.hero .container {
  padding-top: 15%;
  max-width: 1600px; /* Adjust maximum width as per your design */
  margin: 0 auto; /* Center align the container */
}

.moving-yellow-container {
  background-color: rgb(0, 88, 146);
  padding-bottom: 80px;
  padding-top: 80px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  overflow: hidden; /* Hide the bottom part of the container */
  margin-top: -110px; /* Optional: adjust margin as needed */
  transform: translateY(100%); /* Initial position shifted down */
 
 
  width: 100%; /* Adjust width to fit content or use width: 100vw; for full viewport width */
  height: 100%; /* Example height adjustment */
}


.moving-yellow-container.show {
  transform: translateY(10%); /* Move up to original position */
  opacity: 1; /* Show element */
}

.hero h1 {
  color: #ffffff;
  font-size: 42px;
  padding-top: 20px; /* Reduced padding-top */
}

.hero p {
  color: #fff;
  opacity: 0.8;
}

form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}
/* hero.css */
.flip-text {
  display: flex;
  justify-content: center; /* Center horizontally */
  text-align: center;
  width: 100%;
  position: relative;
  margin: 10px 0; /* Adjust spacing */
}

/* hero.css */
.flip-text h2 {
  color: white;
  font-size: 1.5rem; /* Adjust the font size as needed */
  margin: 0;
  opacity: 0; /* Initially hidden */
  transform: translateX(-50px); /* Start off to the left */
  animation: slide-in 0.5s forwards; /* Faster animation */
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}



@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.hidden {
  display: none;
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               

form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}

form span {
  font-size: 14px;
  color: grey;
}

input::placeholder {
  font-size: 17px;
  color: black;
}

form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

form .box:nth-child(1) {
  border-left: none;
}

form h4 {
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}
