/* Base styles for the FRM component */
html, body {
  margin: 0;
  padding: 0;
}

.frm-container {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}



.frm-title {
  margin-bottom: 20px;
  font-size: 28px;
}

.frm-subtitle {
  margin-bottom: 20px;
  font-size: 24px; /* Default font size */
}

ol {
  width: 80%;
  margin: 0 auto 20px;
  text-align: left;
  padding-left: 20px;
  list-style-position: inside;
}

ol li {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

button.apply-button {
  display: block;
  margin: 20px auto;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button.apply-button:hover {
  background-color: #0056b3;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .overlay-text-frm {
    font-size: 28px; /* Adjust font size for mobile */
    margin-top: 15%;
  }

  .frm-title {
    font-size: 24px; /* Adjust font size for mobile */
  }

  .frm-subtitle {
    font-size: 20px; /* Adjust font size for mobile */
  }

  ol {
    width: 90%; /* Increase width for mobile */
  }

  ol li {
    font-size: 16px; /* Smaller font size for mobile */
  }

  button.apply-button {
    padding: 10px 20px; /* Adjust padding for mobile */
    font-size: 16px; /* Smaller font size for mobile */
  }

  .frm-container {
    padding: 10px; /* Adjust padding for mobile */
  }
}
