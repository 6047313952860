.far-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.far-title {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px;
  animation: slideInDown 1s ease-in-out;
}

.far-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.far-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  animation: slideInUp 0.5s ease-in-out;
}

.far-item.left {
  flex-direction: row;
}

.far-item.right {
  flex-direction: row-reverse;
}

.far-image {
  max-width: 450px;
  max-height: 350px;
  object-fit: cover;
  border-radius: 10px;
}

.far-text {
  flex: 1;
}

.far-text h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.far-text p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .far-content {
    gap: 20px;
  }

  .far-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .far-item.left,
  .far-item.right {
    flex-direction: column;
  }

  .far-image {
    width: 100%;
    height: auto;
  }

  .far-text h2 {
    font-size: 1.3em;
  }

  .far-text p {
    font-size: 1em;
  }
}


.factor-image-container{
  width: 100%;
  height: 67vh; /* Adjust the height as needed */
  overflow: hidden;
  margin-bottom: 20px;
  position: relative; /* Add position relative to contain absolute overlay */
}

/* Style the image */
.factor-image{
  width: 100%;
  height: 100vh; /* Set the height to half of the viewport height */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center top; /* Position the image to show the top half */
  margin-bottom: 20px;
}
.overlay-text-factor{
  position: absolute;

  top: 50%; /* Adjust top position */
  left: 50%; /* Adjust left position */

  top: 55%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlayfactor{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}