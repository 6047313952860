/* Set margin and padding to 0 for the whole page */
html, body {
  margin: 0;
  padding: 0;
}

/* Style the container for the NonQM component */
.nonqm-container {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

/* Style the container for the image */
.nonqm-image-container {
  width: 100%;
  height: 67vh; /* Adjust the height as needed */
  overflow: hidden;
  margin-bottom: 20px;
  position: relative; /* Add position relative to contain absolute overlay */
}

/* Style the image */


/* Style the heading */
h1 {
  font-size: 28px; /* Set the font size for the heading */
  margin: 20px auto; /* Center the heading horizontally */
}

/* Style the introductory paragraph */
p {
  width: 80%;
  margin: 0 auto 20px;
  font-size: 18px; /* Set the font size for the introductory paragraph */
  line-height: 1.6; /* Set the line height */
  text-align: justify;
}

/* Style the list of benefits */
ol {
  width: 80%;
  margin: 0 auto 20px;
  text-align: left; /* Align the list items to the left */
  padding-left: 20px;
  list-style-position: inside;
}

/* Style the individual benefits */
ol li {
  font-size: 18px; /* Set the font size for list items */
  line-height: 1.6; /* Set the line height */
  margin-bottom: 10px; /* Add space below each list item */
}

/* Style the types of Non-QM loans list */
.nonqm-types {
  width: 80%;
  margin: 0 auto 20px;
  text-align: left; /* Align the list items to the left */
  padding-left: 20px;
  list-style-type: none; /* Remove default list style */
}

.nonqm-types li {
  font-size: 18px; /* Set the font size for list items */
  line-height: 1.6; /* Set the line height */
  margin-bottom: 10px; /* Add space below each list item */
}

/* Style the apply button */
.apply-button {
  padding: 10px 20px; /* Add padding to the button */
  font-size: 18px; /* Set the font size for the button */
  background-color: #007bff; /* Set the background color for the button */
  color: white; /* Set the text color for the button */
  border: none; /* Remove the button border */
  border-radius: 5px; /* Add border radius to the button */
  cursor: pointer; /* Set cursor to pointer for better interactivity */
  transition: background-color 0.3s; /* Add a smooth transition for hover effect */
}

/* Style the apply button hover effect */
.apply-button:hover {
  background-color: #0056b3; /* Darken the background color on hover */
}

