.mortgage-calculator {
  background-color: #fff;
  border-radius: 10px;
  max-width: 1200px;
  margin: 20px auto;
  padding: 4rem 2rem 2rem; /* Added top padding for gap */
}

.mortgage-calculator h1 {
    margin-top: 60px;
  font-size: 28px;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.mortgage-calculator .subtitle1 {
  text-align: center;
  color: #777;
  margin-bottom: 20px;
}

.calculator-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-section {
  flex: 1 1 40%;
  max-width: 40%;
  padding: 10px;
}

.output-section {
  flex: 1 1 60%;
  max-width: 60%;
  padding: 10px;
  text-align: center;
}

.input-section .MuiTextField-root,
.input-section .MuiSlider-root,
.input-section .MuiSelect-root {
  width: 100%;
  margin-bottom: 20px;
}

.input-section .MuiButton-root {
  width: 100%;
  margin-bottom: 20px;
}

.payment-breakdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.payment-details {
    
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}

.payment-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.payment-details li {
    color: #363636;
    font-weight: bolder;
  margin-bottom: 10px;
}

.total-payment {
  font-weight: bold;
  margin-top: 20px;
  font-size: 18px;
}

.doughnut-chart {
  position: relative;
  margin: auto;
  width: 250px;
}

@media (max-width: 768px) {
  .input-section,
  .output-section {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .payment-details {
    margin-top: 0;
  }
}