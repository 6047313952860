/* Overlay text styling */
.overlay-text-factor {
  position: absolute;
  top: 70%;
  left: 35%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Overlay background styling */
.overlayfactor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

/* About section container styling */
.about {
  margin-bottom: 80px;
  position: relative;
}

/* Welcome container styling */
.welcome-container {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align all child elements */
  text-align: center; /* Center text inside container */
}

/* Styling for paragraphs */
.about p {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 30px;
}

/* Button styling */
.welcome-container button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.welcome-container button a {
  color: white; /* Ensure link text is visible */
  text-decoration: none;
}

.welcome-container button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Fullscreen background image */
.abtimg-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center bottom;
  background-position: center;
  background-repeat: no-repeat;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .about p {
    padding: 0;
  }
  .about .welcome-container h1 {
    text-align: center;
  }
  .about .welcome-container .content-container {
    text-align: center;
  }
}
