/* Base styles for the ARM component */
.arm-image-container {
  width: 100%;
  height: 67vh; /* Adjust the height as needed */
  overflow: hidden;
  margin-bottom: 20px;
  position: relative; /* Add position relative to contain absolute overlay */
}

.arm-image {
  width: 100%;
  height: 100vh; /* Set the height to the viewport height */
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center bottom; /* Position the image to show the bottom half */
  margin-bottom: 20px;
}

.overlay-text-arm {
  position: absolute;
  top: 50%; /* Adjust top position */
  left: 50%; /* Adjust left position */
  transform: translate(-50%, -50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.overlayarm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.arm-content {
  padding: 20px;
}

.arm-title {
  margin-bottom: 20px;
}

/* Specific styles for titles */
.main-title {
  font-size: 28px;
}

.sub-title {
  font-size: 24px;
}

/* Styles for mobile view */
@media screen and (max-width: 768px) {
  .overlay-text-arm {
    font-size: 28px; /* Adjust font size for mobile view */
  }

  .arm-title {
    text-align: center; /* Center-align text for mobile view */
    
  }

  .main-title {

    margin-left: 15px;
  }

  .sub-title {
    font-size: 18px; /* Adjust font size for mobile view */
  }
}
